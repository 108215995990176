<template>
  <div class="container">
    <v-row class="">
      <v-col cols="12" class="top-panel">
        <div class="add-btn" @click="createItem">
          <img src="../assets/img/plusAdd.svg" alt="pluss" />
          <p class="add-text">Добавить</p>
        </div>
        <div class="vertical-line"></div>
      </v-col>
    </v-row>

    <div id="manufacturer">
      <v-container class="secondary mt-5">
        <v-row>
          <v-col>
            <v-data-table :headers="headers" :items="filteredCategorys" :search="search" :footer-props="footerProps"
              :items-per-page="itemsPerPage">
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="page-title">Результаты
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <div class="search-component">
                    <input type="search" class="search-component__input mr-10" v-model="search"
                      append-icon="mdi-magnify" placeholder="Поиск" single-line hide-details />
                    <svg width="17" height="17" class="search-component__icon">
                      <use href="../assets/icons/icons.svg#search-icon"></use>
                    </svg>
                  </div>
                  <!-- <v-text-field
                      class="mr-10"
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Поиск"
                      single-line
                      hide-details
                    ></v-text-field> -->
                  <!-- <button id="staff-add-button" @click="createItem">
                      Создать новый ресторан +
                    </button> -->
                  <v-dialog v-model="dialogCreate" max-width="537px">
                    <v-card>
                      <v-card-title class="modal-title">
                        {{
          isEditing === true
            ? "Редактирование результата"
            : "Новый результат"
        }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeDialog">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Название</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="nameRU"
                                ref="nameRUTextarea" :placeholder="isnameRUFocused
          ? ''
          : textareaPlaceholder
          " @focus="isnameRUFocused = true" @blur="isnameRUFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pb-0 pt-3">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Название EN</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="nameEN"
                                ref="categorynameENTextarea" :placeholder="isnameENFocused
          ? ''
          : textareaPlaceholder
          " @focus="isnameENFocused = true" @blur="isnameENFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>

                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Описание RU</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="descriptionRU"
                                ref="categoryNameTextarea" :placeholder="isdescriptionRUFocused
          ? ''
          : textareaPlaceholder
          " @focus="isdescriptionRUFocused = true"
                                @blur="isdescriptionRUFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>

                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Описание EN</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="descriptionEN"
                                ref="categoryNameTextarea" :placeholder="isdescriptionENFocused
          ? ''
          : textareaPlaceholder
          " @focus="isdescriptionENFocused = true"
                                @blur="isdescriptionENFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>


                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Город RU</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="cityRU"
                                ref="categoryNameTextarea" :placeholder="iscityRUFocused
          ? ''
          : textareaPlaceholder
          " @focus="iscityRUFocused = true" @blur="iscityRUFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>


                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Город EN</p>
                            </v-col>
                            <v-col cols="1" class="py-0 pl-1 red-dot-margin-1">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="cityEN"
                                ref="categoryNameTextarea" :placeholder="iscityENFocused
          ? ''
          : textareaPlaceholder
          " @focus="iscityENFocused = true" @blur="iscityENFocused = false"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>


                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">От какого</p>
                            </v-col>

                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <input type="date" style="height: 15px; width: 110px"
                                class="col-description col-input-date " v-model="dateFrom" ref="orderNumTextarea"
                                value="dateFrom">
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>

                        <v-col cols="12" class="py-0 px-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">До какого</p>
                            </v-col>

                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <input type="date" style="height: 15px; width: 110px"
                                class="col-description col-input-date" v-model="dateTo" ref="orderNumTextarea"
                                value="dateTo">
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>

                        <v-col cols="12" class="px-0 py-0">
                          <div class="col-content px-0 pt-3 pb-0">
                            <v-col cols="4" class="col-paddings-r-l px-0 py-0">
                              <p class="col-title">Номер в списке</p>
                            </v-col>
                            <v-col cols="1" class="py-0 red-dot-margin-2">
                              <span class="red-dot">*</span>
                            </v-col>
                            <v-col cols="7" class="col-paddings-r-l px-0 py-0">
                              <textarea style="height: 44px" class="col-description" v-model="orderNum"
                                ref="orderNumTextarea" :placeholder="isorderNumFocused ? '' : textareaPlaceholder
          " @focus="isorderNumFocused = true" @blur="isorderNumFocused = false"
                                @input="handleInput"></textarea>
                            </v-col>
                          </div>
                        </v-col>
                        <div class="horizontal-line"></div>
                      </v-card-text>

                      <div :class="isEditing === true
          ? 'btn-container'
          : 'btn-container-1 px-6'
          ">
                        <div v-if="isEditing" :class="currentState === 'Active'
          ? 'save-btn-second'
          : 'delete-btn'
          " text @click="setCategoryState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                        <div class="cancel-btn" text @click="closeCreate">
                          Отмена
                        </div>

                        <div :class="isEditing === true ? 'save-btn' : 'save-btn ml-7'
          " text @click="handleButtonClick">
                          Сохранить
                        </div>
                      </div>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="750px">
                    <v-card>
                      <v-card-title class="warning-title" style="">{{
          currentState === "Active"
            ? "Вы точно хотите активировать данную категорию?"
            : "Вы точно хотите деактивировать данную категорию?"
        }}
                        <v-btn icon @click="closeDelete">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </v-card-title>
                      <v-card-actions class="warning-margin">
                        <div class="cancel-btn" text @click="closeDelete">
                          Отмена
                        </div>
                        <div :class="currentState === 'Active'
          ? 'save-btn-paswd'
          : 'del-btn'
          " text @click="setCategoryState">
                          {{
          currentState === "Active"
            ? "Активировать"
            : "Деактивировать"
        }}
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :timeout="3000" class="btn-contaiener">
                  {{ message }}
                  <template v-slot:action="{ attrs }">
                    <div class="snackbar-btn" text v-bind="attrs" @click="snackbar = false">
                      Закрыть
                    </div>
                  </template>
                </v-snackbar>
              </template>
              <template v-slot:item="{ item, index }">
                <tr style="cursor: pointer">
                  <td @click="entetResult(item)">
                    {{ item.name || " " }}
                  </td>
                  <td @click="entetResult(item)">
                    {{ item.sortOrder || " " }}
                  </td>
                  <td @click="entetResult(item)">
                    {{ item.createdOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="entetResult(item)">
                    {{ item.modifiedOn | formatDate("dd.MM.yyyy") }}
                  </td>
                  <td @click="entetResult(item)">
                    {{ item.createdBy.fullName }}
                    <!-- <span v-for="(localization, index) in item.localizations" :key="index">
                        {{ localization.locale }}: {{ localization.name }}<br>
                    </span> -->
                  </td>
                  <td @click="entetResult(item)">
                    {{ getStatusTranslation(item.stateCode) }}
                  </td>
                  <td style="text-align: right">
                    <v-icon medium @click="toggleActionMenu(index, $event)">mdi-dots-vertical</v-icon>
                    <div class="ActionMenu" v-if="showActionMenu[index]" :ref="'ActionMenu' + index">
                      <div @click="entetResult(item)" class="ActionMenu-item-1">
                        Редактировать
                      </div>
                      <div class="divider-modal"></div>
                      <div @click="deleteItem(item)" class="ActionMenu-item-2">
                        {{
          item.stateCode === "Active"
            ? "Деактивировать"
            : "Активировать"
        }}
                      </div>
                    </div>
                  </td>
                </tr>
              </template>
              <template v-slot:no-data>
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </template>

            </v-data-table>
            <template v-slot:footer>
              <div class="v-data-footer">
                <div class="v-data-footer__select">
                  Кол-во элементов на странице:
                  <v-select class="custom-select" v-model="itemsPerPage" :items="footerProps.itemsPerPageOptions"
                    @change="changeItemsPerPage" hide-details></v-select>
                </div>
                <div class="v-data-footer__pagination">
                  {{ (currentPage - 1) * itemsPerPage + 1 }} -
                  {{ Math.min(currentPage * itemsPerPage, totalItems) }} из
                  {{ totalItems }}
                </div>
                <div class="v-data-footer__icons-before">
                  <button type="button" :disabled="currentPage === 1"
                    class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                    :class="{ 'v-btn--disabled': currentPage === 1 }" aria-label="Предыдущая страница"
                    @click="goToPreviousPage">
                    <span class="v-btn__content">
                      <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-left theme--light"></i>
                    </span>
                  </button>
                </div>
                <div class="v-data-footer__icons-after">
                  <button type="button" :disabled="currentPage === totalPages"
                    class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                    :class="{ 'v-btn--disabled': currentPage === totalPages }" aria-label="Следующая страница"
                    @click="goToNextPage">
                    <span class="v-btn__content">
                      <i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-right theme--light"></i>
                    </span>
                  </button>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
export default {
  directives: { mask },
  name: "Results",

  data: () => ({
    showActionMenu: [],
    isHovered: false,
    isHoveredTogo: false,
    showInputMenu: false,
    selectedOption: "",
    isPlaceholderHidden: false,
    isnameRUFocused: false,
    isnameENFocused: false,
    isorderNumFocused: false,
    iscityENFocused: false,
    iscityRUFocused: false,
    selectedRoles: null,
    descriptionRU: "",
    descriptionEN: "",
    cityRU: "",
    cityEN: "",
    isdescriptionENFocused: false,
    isdescriptionRUFocused: false,
    nameRU: "",
    nameEN: "",
    dateFrom: "",
    dateTo: "",
    orderNum: null,
    dialogDelete: false,
    dialogCreate: false,
    currentState: "",
    search: "",
    snackbar: false,
    message: "",
    statusTranslations: {
      Active: "Активный",
      Inactive: "Неактивный",
    },
    newResult: {},
    result: {},
    selectedType: null,
    headers: [
      {
        text: "Название",
        align: "start",
        value: "name",
      },
      { text: "Номер в списке", value: "sortOrder" },
      { text: "Дата создания", value: "createdOn" },
      { text: "Дата изменения", value: "modifiedOn" },
      { text: "Пользователь", value: "createdBy.fullName" },
      { text: "Статус", value: "stateCode" },
      { text: "Действия", align: "end", value: "actions", sortable: false },
    ],
    categorys: [],
    profiles: [],
    error: null,
    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
    totalPages: 0,
    isEditing: false,
    currentCategory: "",
  }),

  created() {
    this.initialize();
    // this.profileInfo();
    document.addEventListener("click", this.closeActionMenu);
  },
  computed: {
    filteredCategorys() {
      // Фильтруем данные внутри таблицы в зависимости от выбранного типа
      if (this.selectedType) {
        return this.categorys.items
          .filter
          // (item) => item.rolesTest === this.selectedType
          ();
      } else {
        return this.categorys.items;
      }
    },
    footerProps() {
      return {
        itemsPerPageOptions: [3, 5, 7, 10], // Опции количества элементов на странице
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalItems,
      };
    },
    ...mapGetters({
      user: "user",
    }),
    textareaPlaceholder() {
      return this.isPlaceholderHidden ? "" : "---";
    },
  },

  mounted() {
    document.addEventListener("click", this.closeActionMenu);

    window.addEventListener("click", this.handleClickOutside);
    window.addEventListener("click", this.handleClickOutsideTogo);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closeActionMenu);

    window.removeEventListener("click", this.handleClickOutside);
    window.removeEventListener("click", this.handleClickOutsideTogo);
  },

  methods: {
    handleInput() {
      // Оставить только цифры
      this.orderNum = this.orderNum.replace(/\D/g, "");
    },
    toggleActionMenu(index, event) {
      event.stopPropagation(); // Остановить распространение события, чтобы оно не закрыло меню
      // Закрыть все другие меню
      for (let i = 0; i < this.showActionMenu.length; i++) {
        if (i !== index) {
          this.$set(this.showActionMenu, i, false);
        }
      }
      // Переключить меню для выбранного элемента
      this.$set(this.showActionMenu, index, !this.showActionMenu[index]);

      // Добавляем обработчик события на документе для закрытия меню при клике вне меню
      if (this.showActionMenu[index]) {
        this.$nextTick(() => {
          document.addEventListener("click", this.handleClickOutside);
        });
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    handleClickOutside(event) {
      let clickedOutside = true;
      if (this.items && this.items.length > 0) {
        for (let i = 0; i < this.items.length; i++) {
          const menuRef = this.$refs["ActionMenu" + i];
          if (menuRef && menuRef.contains(event.target)) {
            clickedOutside = false;
            break;
          }
        }
      }
      if (clickedOutside) {
        for (let i = 0; i < this.showActionMenu.length; i++) {
          this.$set(this.showActionMenu, i, false);
        }
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    getStatusTranslation(status) {
      return this.statusTranslations[status] || status;
    },
    getEnglishRoleTranslation(role) {
      for (const key in this.roleTranslations) {
        if (Object.prototype.hasOwnProperty.call(this.roleTranslations, key)) {
          if (this.roleTranslations[key] === role) {
            return key;
          }
        }
      }
      return role;
    },
    hidePlaceholder(inputName) {
      if (inputName === "nameRU") {
        this.isnameRUFocused = true;
      } else if (inputName === "nameEN") {
        this.isnameENFocused = true;
      }
    },

    restorePlaceholder() {
      this.isPlaceholderHidden = false;
      // this.activeTextarea = null;
    },

    adjustTextareaHeight(refName) {
      const textarea = this.$refs[refName];
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    },
    closeDialog() {
      this.dialogCreate = false;
      this.nameRU = "";
      this.selectedOption = "";
      this.nameEN = "";
      this.isEditing = false;
    },
    handleButtonClick() {
      if (this.isEditing) {
        // Выполните метод для редактирования
        this.editResult();
      } else {
        // Выполните метод для создания нового приложения
        this.createNewResult();
      }
    },
    initialize() {
      this.$http
        .put("/api/Results/PagedList", {
          pageNumber: this.currentPage,
          pageSize: this.pageSize,
          sorting: {
            property: "CreatedOn",
            isDescending: true,
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          this.categorys = response.data;
          this.totalPages = response.data.totalPages; // Общее количество страниц
          this.totalItems = response.data.totalCount; // Общее количество элементов
        })
        .catch((error) => (this.error = error));
    },
    goToNextPage() {
      if (this.currentPage < this.totalItems) {
        this.currentPage++;
        this.initialize();
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.initialize();
      }
    },
    changeItemsPerPage() {
      this.currentPage = 1; // Сбрасываем текущую страницу при изменении количества элементов на странице
      this.initialize(); // Вызов метода для загрузки данных с новым количеством элементов на странице
    },
    deleteItem(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.editedIndex = this.filteredCategorys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";
      this.dialogDelete = true;
    },
    entetResult(item) {
      this.$router.push({ path: `/result-body/${item.id}` });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    createItem() {
      this.dialogCreate = true;
      this.nameRU = "";
      this.orderNum = null;
      this.nameEN = "";
      this.cityRU = "";
      this.cityEN = "";
      this.isEditing = false;
      // this.getEmployee();
    },

    closeCreate() {
      this.dialogCreate = false;
      this.nameRU = "";
      this.orderNum = "";
      this.nameEN = "";
      this.cityRU = "";
      this.cityEN = "";
      this.isEditing = false;
    },

    createNewResult() {
      //   this.newEmp.employeeRole = this.getEnglishRoleTranslation(
      //     this.selectedOption
      //   );
      (this.newResult.name = this.nameRU),
        (this.newResult.description = this.descriptionRU),
        (this.newResult.city = this.cityRU),

        (this.newResult.dateTo = this.dateTo),
        (this.newResult.dateFrom = this.dateFrom),
        // (this.newEmp.mobilePhone = this.phone),
        (this.newResult.localizations = [
          {
            locale: "en",
            name: this.nameEN,
            description: this.descriptionEN,
            city: this.cityEN
          },
        ]),
        (this.newResult.sortOrder = this.orderNum),
        this.$http
          .post("/api/Results", this.newResult)
          .then(() => {
            this.initialize();
            this.dialogCreate = false;
            this.newResult = {
              name: "",
              description: "",
              city: "",
              sortOrder: null,
              localizations: [
                {
                  locale: "",
                  name: "",
                  city: "",
                  description: ""
                },
              ],
              //   employeeRole: null,
            };
            this.snackbar = true;
            this.message = "Категория успешно добавлена";
          })
          .catch((error) => {
            this.error = error;
            this.snackbar = true;
            this.message = "Ошибка добавление категории";
          });
    },
    getCategory(item) {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.createItem();
      this.isEditing = true;
      this.editedIndex = this.filteredCategorys.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.currentCategory = item.id;
      this.currentState = item.stateCode === "Active" ? "Inactive" : "Active";

      this.$http
        .get(`/api/Results/${item.id}`)
        .then((response) => {
          this.result = response.data;
          this.nameRU = this.result.name;
          this.orderNum = this.result.sortOrder;
          this.result.localizations.forEach((localization) => {
            // Проверка, что текущая локализация имеет имя (если есть)
            if (localization.name) {
              // Здесь вы можете использовать localization.name по вашему усмотрению
              this.nameEN = localization.name;
            }
          });
        })
        .catch((error) => {
          alert(error);
        });
    },
    editResult() {
      this.newResult = {};
      this.result.name = this.nameRU;
      this.category.id = this.currentCategory;
      this.category.sortOrder = this.orderNum;
      (this.category.localizations = [
        {
          categoryId: this.currentCategory,
          locale: "en",
          name: this.nameEN,
        },
      ]),
        (this.newResult.modifiedBy = this.user.userInf),
        (this.newResult.createdBy = this.result.createdBy),
        this.$http
          .put(`/api/Results`, this.result, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.initialize();
            this.dialogCreate = false;
            this.result = response.data;
            this.newCategory = {
              id: "",
              name: "",
              localizations: [
                {
                  categoryId: "",
                  locale: "",
                  name: "",
                },
              ],
              createdBy: this.result.createdBy,
              modifiedBy: "f",
            };
            this.snackbar = true;
            this.message = "Категория успешно изменена";
          })
          .catch(() => {
            this.snackbar = true;
            this.message = "Ошибка редактирования категории";
          });
    },
    setCategoryState() {
      this.showActionMenu = this.showActionMenu.map(() => false);
      this.$http
        .put(`/api/Results/SetState/${this.currentCategory}`, null, {
          params: {
            stateCode: this.currentState,
          },
        })
        .then((response) => {
          this.curUser = response.data;
          this.initialize();
          this.closeDelete();
          this.dialogCreate = false;
          this.snackbar = true;
          this.message = "Статус усмешно изменен";
        })
        .catch(() => {
          this.snackbar = true;
          this.message = `Ошибка выполнения`;
        });
    },
    // editItem(item) {
    //   this.$router.push({ path: `/admin/application/${item.id}` });
    // },
    toggleInputMenu() {
      this.isHovered = false;
      this.showInputMenu = !this.showInputMenu;
    },

    selectOption(option) {
      this.selectedOption = option;
      this.toggleInputMenu(); // Вызовите метод для скрытия меню (если это требуется)
    },

    addBorderClass() {
      this.isHovered = true;
    },
    removeBorderClass() {
      this.isHovered = false;
    },
  },

  watch: {
    categoryName() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("categoryNameTextarea");
      });
    },

    categoryNameEn() {
      this.$nextTick(() => {
        this.adjustTextareaHeight("categoryNameEnTextarea");
      });
    },
  },
};
</script>

<style scoped>
.v-card__actions {
  align-items: center;
  display: flex;
  padding: 8px;
  justify-content: flex-end;
}

.btn-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 46px;
  align-items: center;
}


.warning-margin {
  margin-top: 16px;
  margin-bottom: 10px;
}

.warning-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
  word-break: break-all;
  justify-content: space-between;
  padding: 31px 14px 10px 31px !important;
}

.save-btn-paswd {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

::v-deep .v-toolbar__content {
  align-items: center !important;
}

.ActionMenu {
  position: absolute;
  /* bottom: -110px;
  left: -180px; */
  width: 156px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  right: 2%;
}

.ActionMenu-item-1:hover,
.ActionMenu-item-2:hover {
  background-color: rgba(242,
      242,
      246,
      0.5);
  /* изменяем цвет текста при наведении мыши */
}

.ActionMenu-item-1 {
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  border-top-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-top-right-radius: 10px;
  /* Закругляем нижний правый угол */
  cursor: pointer;
  text-align: start;
}

.ActionMenu-item-2 {
  border-bottom-left-radius: 10px;
  /* Закругляем нижний левый угол */
  border-bottom-right-radius: 10px;
  /* Закругляем нижний правый угол */
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;
  width: 100%;
  text-align: start;
  cursor: pointer;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

/* Custom styles for the "Закрыть" button */
.custom-snackbar .v-btn--text.v-btn--success {
  color: #fff;
  /* White text color */
}

::v-deep .v-data-table-header__icon.mdi-arrow-up,
::v-deep .v-data-table-header__icon.mdi-arrow-down {
  display: inline-block;
}

::v-deep .v-data-table-header__icon.mdi-arrow-up::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-up */
  font-family: "Material Design Icons";
}

/* Значок сортировки по убыванию */
::v-deep .v-data-table-header__icon.mdi-arrow-up.rev::before,
::v-deep .v-data-table-header__icon.mdi-arrow-down.rev::before {
  content: "\F054F";
  /* Unicode код для иконки mdi-arrow-down */
  font-family: "Material Design Icons";
}

.custom-snackbar .v-btn--text.v-btn--success:hover {
  background-color: #357a38;
  /* Darker green background on hover */
}

.role-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  /* Другие стили по вашему усмотрению */
}

.v-col.cols-7 .border-hover {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
}

.profile-menu-item:hover {
  /* Здесь вы можете задать стили для hover-эффекта */
  background-color: #f0f0f0;
  /* Пример: изменение цвета фона при наведении */
  color: #333;
  /* Пример: изменение цвета текста при наведении */
  cursor: pointer;
  /* Пример: изменение курсора при наведении */
  /* Другие стили по вашему усмотрению */
}

.border-input {
  border-radius: 8px;
  border: 1px solid var(--03, #c1c1c1) !important;
  background: var(--white, #fff);
  padding: 6px;
}

.border-white {
  border-radius: 8px;
  border: 1px solid var(--02, white);
  background: var(--white, #fff);
  padding: 6px;
  cursor: pointer;
  width: 100%;
}

.divider-modal {
  background: #e0e0e0;
  height: 1px;
  width: 100%;
  /* Расширяем на всю ширину родительского контейнера */
}

.profile-menu-item {
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  padding-left: 30px;
  padding-top: 11px;
  padding-bottom: 11px;
  width: 100%;
}

.input-menu {
  position: absolute;
  bottom: 32%;
  left: 30.7%;
  width: 347px;
  height: auto;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(200, 200, 200, 0.52);
  transition: bottom 0.3s;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 100;
  /* right: -15px; */
}

.select-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  /* margin-left: 15px; */
}

.option-btn {
  margin-bottom: 0px;
  color: var(--Gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-left: 5px;
}

::v-deep .v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 46px;
  align-items: center;
}

.red-dot-margin-2 {
  margin-left: -17px;
}

.red-dot-margin-1 {
  text-align: center;
  margin-left: -17px;
}

.btn-contaiener {
  display: flex;
  justify-content: space-evenly;
}

.cancel-btn {
  color: var(--02, #848484);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  border: 1px solid var(--02, #848484);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.delete-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.save-btn-second {
  color: #00b800;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.snackbar-btn {
  color: var(--Red, #eb5757);
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  line-height: 1.25rem;
  margin-right: auto;
  padding: 14px 16px;
  text-align: initial;
  cursor: pointer;
}

.save-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #00b800);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
}

.del-btn {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: var(--logo, #b80900);
  display: inline-flex;
  padding: 13px 29px;
  justify-content: center;
  align-items: center;
  gap: 32.5px;
  cursor: pointer;
  margin-left: 30px;
}

.text-limit {
  width: 30ch;
  /* Отображение примерно 10 символов */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--Gray-1, #333);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
}

.horizontal-line {
  /* Ширина div'а будет равна 100% от родительского контейнера */
  height: 1px;
  /* Толщина линии */
  background: #848484;
  /* Цвет линии (в данном случае черный) */
}

.custom-select {
  position: relative;
  width: 350px;
  cursor: pointer;
  /* margin-left: 9px; */
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
}

.select-arrow {
  position: absolute;
  top: 40%;
  right: 30px;
  /* transform: translateY(-50%); */
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #333;
  /* Цвет стрелочки */
}

.col-input-date {
  padding: 25px 0;
  align-items: center;
}

.col-description,
:focus {
  outline: none;
  /* Убираем внешний контур (outline) при фокусе */
  border: none;
  /* Убираем границу (border) при фокусе */
  box-shadow: none;
  /* Убираем тень (box-shadow) при фокусе */
}

.red-dot {
  color: #e41313;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.col-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-wrap: break-word;
  margin-bottom: 0px;
}

.col-paddings-r-l {
  padding-right: 0px;
  padding-left: 0px;
}

.col-content {
  display: flex;
  align-items: flex-start;
}

.role-margin {
  margin-right: 37px;
}

.role-margin-1 {
  margin-right: 30px;
}

.col-content-2 {
  display: flex;
  align-items: center;
  width: fit-content;
}

.col-description {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 146.667% */
  margin-bottom: 0px;
  word-wrap: break-word;
  resize: none;
  height: 30px;
  /* min-height: 20px; */
  width: 100%;
  /* margin-left: 18px; */
}

::v-deep .v-dialog {
  border-radius: 20px;
  background: #fff;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none;
}

.modal-title {
  color: var(--gray-1, #333);
  font-family: Inter !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal;
}

.page-title {
  color: var(--gray-1, #333);
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vertical-line {
  width: 1px;
  height: 42px;
  background: #e0e0e0;
  margin-left: 31px;
}

.vertical-line-1 {
  width: 1px;
  height: 50px;
  background: #e0e0e0;
}

.add-btn .add-text {
  color: var(--01, #333);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-left: 12px;
  transition: color 0.3s ease;
}

.add-btn:hover .add-text {
  /* Цвет текста при наведении */
  color: black;
  /* Замените "red" на желаемый цвет текста */
  font-weight: 500;
  font-size: 17px;
}

.add-btn {
  display: flex;
  align-items: center;
  padding: 12px 0px;
  /* margin-left: 37px; */
  cursor: pointer;
}

.top-panel {
  border-bottom: 1px solid var(--04, #eaeaea);
  background: var(--white, #fff);
  height: 50px;
  display: flex;
  align-items: center;
}

.search-container {
  padding-left: 40px;
  padding-right: 30px;
  padding-top: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img-pluss-btn {
  margin-left: 10px;
}

.arrow-btn {
  /* border-right: 1px solid var(--04, #eaeaea); */
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.search-notfound {
  /* display: flex; */
  margin: 0 auto;
  font-family: "Inter";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
}

.search-component {
  /* max-width: 606px; */
  width: 30vw;
  height: 45px;
  margin: 0 auto;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-component__input {
  border-radius: 100px;
  border: 1px solid var(--gray-5, #e0e0e0);
  width: 100%;
  outline: unset;
  padding: 0 40px 0 15px;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}

.search-component__icon {
  position: absolute;
  top: 30%;
  right: 55px;
}

#staff-add-button {
  border-radius: 8px;
  background-color: #333;
  color: var(--white, #fff);
  gap: 12px;
  padding: 10px 56px;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  height: 45px;
}

::v-deep tbody tr:nth-child(2n) {
  background: rgba(242, 242, 246, 0.5);
}

::v-deep .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

::v-deep .v-data-table__wrapper>table>thead>tr>th {
  color: var(--gray-1, #333) !important;
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: thin solid #848484;
}

@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

@media (min-width: 960px) {
  .container {
    max-width: 100% !important;
  }
}
</style>
